<template>
  <div class="card">
    <div class="card-body">
      <h3>Two-Way Texting</h3>
      <div v-if="plan" class="livechat-plan">
        <h4>Price</h4>
        <h5 v-if="coupon">$ {{ plan.price - coupon.amount_off }} ( <i class="text-danger" style="text-decoration:line-through;">{{ plan.price }}</i> ) / Month</h5>
        <h5 v-else>${{ plan.price }} / Month</h5>
        <div class="row">
          <!-- eslint-disable vue/no-v-html -->
          <div class="col-md-12" v-html="plan.desc"></div>
          <!--eslint-enable-->
        </div>

        <button v-if="!user.business.is_enabled_livechat" style="width:300px;" class="btn btn-primary" :disabled="isLoadingSubscription" @click="subscription">
          <b-spinner v-if="isLoadingSubscription" small />
          <span v-else>
            Subscribe
          </span>
        </button>
        <h3 v-else class="text-primary text-left mt-4">
          Your Subscription is Active now.
        </h3>
      </div>
      <div v-if="isLoadingPlan" class="text-center">
        <b-spinner />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingPlan: false,
      plan: null,
      couponCode: null,
      isLoadingSubscription: false,
      isLoadingCoupon: false,
      coupon: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getLiveChatPlan()
  },

  methods: {
    getLiveChatPlan() {
      this.isLoadingPlan = true

      this.$store.dispatch('livechat/getPlan')
        .then((plan) => {
          this.isLoadingPlan = false
          this.plan = plan
        })
        .catch(() => {
          this.isLoadingPlan = false
        })
    },

    subscription() {
      this.isLoadingSubscription = true

      this.$store.dispatch('livechat/businessSubscription')
        .then((href) => {
          location.href = href
          this.isLoadingSubscription = false
        })
        .catch(() => {
          this.isLoadingSubscription = false
        })
    },
  }
}
</script>